<script lang="ts" setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import {mdiChevronDown, mdiClose, mdiEmailOutline, mdiSendVariant,} from "@mdi/js";
import {VForm} from "vuetify/components";

enum ConcernValue {
  General = "Allgemeine Anfrage",
  AppDev = "App-Dev",
  Shopware = "Shopware",
  DevOps = "Dev Ops",
  MobileApps = "Mobile Apps",
  AI = "KI",
}

interface ConcernOption {
  value: ConcernValue;
  icon: string;
  text: string;
}

const props = withDefaults(
    defineProps<{
      defaultConcern?: ConcernValue;
      showConcernSelection?: boolean;
      sheetBackground?: string;
      outlineColor?: string;
      submitButtonColor?: string;
      headingLevel?: number;
    }>(),
    {
      defaultConcern: ConcernValue.General,
      showConcernSelection: true,
      sheetBackground: "primary-lighten-2",
      outlineColor: "rgb(var(--v-theme-primary-lighten-1))",
      submitButtonColor: "primary",
      headingLevel: 2,
    },
);

const {t} = useI18n();
const mail = useMail();
const email = ref("");
const message = ref("");
const form = ref<VForm | null>(null);
const selectedConcern = ref<ConcernValue | null>(null);
const isConcernSelectDialogOpen = ref(false);
const showSuccess = ref(false);
const showError = ref(false);
const {smAndDown} = useDisplay();
const honeypotNewPassword = ref("");
const isSubmitted = ref(false);

const messageId = useId();
const emailId = useId();

// Options for the concern dropdown
const concernOptions: ConcernOption[] = [
  {
    value: ConcernValue.General,
    icon: "/images/support.svg",
    text: t("contactForm.concern.general"),
  },
  {
    value: ConcernValue.AppDev,
    icon: "/images/app-dev-logo.svg",
    text: t("contactForm.concern.appDev"),
  },
  {
    value: ConcernValue.DevOps,
    icon: "/images/dev-ops-dark.svg",
    text: t("contactForm.concern.devOps"),
  },
  {
    value: ConcernValue.MobileApps,
    icon: "/images/mobile-apps-logo.svg",
    text: t("contactForm.concern.mobileApps"),
  },
  {
    value: ConcernValue.Shopware,
    icon: "/images/shopware-circle.svg",
    text: t("contactForm.concern.shopware"),
  },
  {
    value: ConcernValue.AI,
    icon: "/images/Saxony-ai-circle.svg",
    text: t("contactForm.concern.ai"),
  },
];

// Validation rules for the email field
const emailRules = [
  (v: string) => !!v || t("contactForm.validation.emailRequired"),
  (v: string) =>
      /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,}$/.test(v) ||
      t("contactForm.validation.emailValid"),
];

// Validation rules for the message field
const messageRules = [
  (v: string) => !!v || t("contactForm.validation.messageRequired"),
];

const isFormValid = ref(false);

// Set initial concern on mounted to ensure v-item-group highlights the selected option correctly.
onMounted(() => {
  selectedConcern.value = props.defaultConcern;
});

// Computed property to get the text of the selected concern
const selectedConcernText = computed(() => {
  const selectedOption = concernOptions.find(
      (option) => option.value === selectedConcern.value,
  );
  return selectedOption ? selectedOption.text : "";
});

const outlineColorStyle = computed(() => {
  return {
    "--outline-color": props.outlineColor,
  };
});

// handle form submission
async function sendForm() {
  try {
    if (honeypotNewPassword.value) {
      showError.value = true;
      return;
    }

    const currentUrl = window.location.href;
    await mail.send({
      replyTo: email.value,
      subject: "Anfrage Kontaktformular: " + selectedConcern.value,
      text: `Nachricht: ${message.value}\n\nGesendet von: ${currentUrl}`,
    });
    form.value?.reset();
    showSuccess.value = true;
    isSubmitted.value = true;
  } catch (error) {
    showError.value = true;
  }
}
</script>

<template>
  <v-sheet
      class="contact-form hw-my-small pb-6 px-6 px-md-10 px-lg-16"
      :color="sheetBackground"
      :style="outlineColorStyle"
  >
    <!-- Show success message if form is submitted -->
    <div v-if="isSubmitted" class="text-center">
      <p class="text-h4 pt-6">{{ t("contactForm.thankYou") }}</p>
      <p class="pt-0">{{ t("contactForm.willContactSoon") }}</p>
    </div>

    <v-form
        @submit.prevent
        v-else
        ref="form"
        v-model="isFormValid"
        class="contact-form--error"
    >
      <component
          :is="(props.headingLevel < 1 ||  props.headingLevel > 5) ? 'div' : 'h' + props.headingLevel"
          class="text-h2"
      >
        {{ t("contactForm.title") }}
      </component>

      <slot name="description">
        <p class="" style="white-space: pre-line">
          {{ t("contactForm.subtitle") }}
        </p>
      </slot>

      <p class="text-strong" v-if="showConcernSelection">
        {{ t("contactForm.request") }}
      </p>

      <!-- Contact methods for up to 'sm' breakpoint -->
      <v-dialog
          max-width="500"
          v-model="isConcernSelectDialogOpen"
          v-if="showConcernSelection"
      >
        <template v-slot:activator="{ props: activatorProps }">
          <HwButton
              underline
              background="#1574ad"
              width="380"
              block
              :icon="[mdiChevronDown]"
              class="d-md-none"
              style="margin-top: 1rem; margin-bottom: 2rem"
              v-bind="activatorProps"
              aria-haspopup="dialog"
              :aria-expanded="isConcernSelectDialogOpen.toString()"
          >
            {{ selectedConcernText || t("contactForm.selectConcern") }}
          </HwButton>
        </template>

        <template v-slot:default>
          <v-card color="#FFF" class="position-relative">
            <HwButton
                class="position-absolute"
                style="top: 0; right: 0"
                @click="isConcernSelectDialogOpen = false"
            >
              <v-icon size="large" :icon="[mdiClose]" />
            </HwButton>
            <v-card-text>
              <p class="text-strong hw-py-small">
                {{ t("contactForm.request") }}
              </p>
              <v-item-group class="w-100" v-model="selectedConcern" mandatory>
                <div v-for="option in concernOptions" :key="option.value">
                  <v-item :value="option.value" v-slot="{ isSelected, toggle }">
                    <button
                        @click="
                        toggle && toggle();
                        isConcernSelectDialogOpen = false;
                      "
                        @keydown.enter.prevent.default="
                        toggle && toggle();
                        isConcernSelectDialogOpen = false;
                      "
                        tabindex="0"
                        variant="text"
                        style="--v-hover-opacity: 0; --v-focus-opacity: 0"
                        :class="[
                        'd-flex',
                        'h-100',
                        'hw-py-small',
                        'concern-card',
                        'concern-card--mobile',
                        {
                          'background transparent-1 concern-card--selected':
                            isSelected,
                        },
                      ]"
                        :aria-selected="isSelected"
                    >
                      <div
                          style="
                          width: 80px;
                          height: 80px;
                          min-width: 80px;
                          min-height: 80px;
                        "
                          :class="[
                          'hw-hexagon',
                          'hw-hexagon--mobile',
                          {
                            'hw-hexagon--selected': isSelected,
                          },
                        ]"
                      >
                        <v-responsive
                            aspect-ratio="1"
                            content-class="d-flex justify-center align-center"
                        >
                          <hw-image :src="option.icon" style="max-width: 70%" />
                        </v-responsive>
                      </div>
                      <div
                          class="d-flex justify-center align-center hw-ml ma-auto concern-card__description"
                      >
                        {{ option.text }}
                      </div>
                    </button>
                  </v-item>
                </div>
              </v-item-group>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>

      <!-- Contact methods for 'md' breakpoint and up -->
      <div
          class="text-center hw-mb-smaller d-none d-md-flex mt-4"
          v-if="showConcernSelection"
      >
        <v-item-group class="w-100" v-model="selectedConcern" mandatory>
          <v-row class="text-center justify-space-between">
            <v-col
                cols="2"
                v-for="option in concernOptions"
                :key="option.value"
                class="concern-card__option d-flex justify-center align-start"
            >
              <v-item :value="option.value" v-slot="{ isSelected, toggle }">
                <button
                    @click="toggle"
                    @keydown.enter="toggle"
                    tabindex="0"
                    variant="text"
                    :aria-selected="isSelected"
                    style="
                    --v-hover-opacity: 0;
                    --v-focus-opacity: 0;
                    padding: 8px;
                  "
                    :class="[
                    'concern-card',
                    'rounded-lg',
                    'h-100',
                    'w-100',
                    'd-flex flex-column align-center',
                    {
                      'background transparent-1': isSelected,
                    },
                  ]"
                >
                  <v-sheet
                      :class="[
                      'mx-auto',
                      'hw-hexagon',
                      'contact-card__option-image-wrapper',
                    ]"
                  >
                    <v-responsive
                        aspect-ratio="1"
                        content-class="d-flex justify-center align-center"
                    >
                      <hw-image :src="option.icon" style="max-width: 70%" />
                    </v-responsive>
                  </v-sheet>
                  <p class="concern-card__description mt-3">
                    {{ option.text }}
                  </p>
                </button>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </div>

      <!-- message field -->
      <v-textarea
          :id="messageId"
          v-model.trim="message"
          rows="3"
          auto-grow
          variant="outlined"
          :rules="messageRules"
          bg-color="#FFFFFF"
          :single-line="true"
          base-color="#1fabff"
          rounded="lg"
          type="text"
          class="message-field"
      >
        <template #label>
          <span class="text-strong"> {{ t("contactForm.message") }} </span>
        </template>
      </v-textarea>

      <v-row>
        <v-col class="pb-0">
          <!-- email field -->
          <v-text-field
              :id="emailId"
              v-model.trim="email"
              variant="outlined"
              :rules="emailRules"
              :prepend-inner-icon="[mdiEmailOutline]"
              bg-color="#FFFFFF"
              :single-line="true"
              class="email-field my-2"
              rounded="lg"
              type="email"
          >
            <template #label>
              <span class="text-strong">
                {{ t("contactForm.email") }}
              </span>
            </template>
          </v-text-field>
        </v-col>

        <v-col md="auto" cols="12" class="pt-md-3 pt-0">
          <!-- submit button -->
          <HwButton
              underline
              :background="submitButtonColor"
              @click="sendForm"
              :icon="[mdiSendVariant]"
              class="ml-auto my-md-2 my-0"
              :disabled="!isFormValid"
              type="submit"
              :full="smAndDown"
              half-padding
              size="dense"
          >
            {{ t("contactForm.send") }}
          </HwButton>
        </v-col>

        <!-- Honeypot field New Password (visually hidden but still in the DOM) -->
        <input
            type="hidden"
            id="new_password"
            name="new_password"
            v-model.trim="honeypotNewPassword"
            autocomplete="new-password"
            tabindex="-1"
        />
      </v-row>
    </v-form>
    <!-- Snackbar for success message -->
    <v-snackbar
        v-model="showSuccess"
        timeout="4000"
        color="success"
        location="bottom right"
        role="alert"
    >
      {{ t("contactForm.success") }}
      <template v-slot:actions>
        <HwButton color="white" text @click="showSuccess = false" icon>
          <v-icon :icon="[mdiClose]"></v-icon>
        </HwButton>
      </template>
    </v-snackbar>

    <!-- Snackbar for error message -->
    <v-snackbar
        v-model="showError"
        color="error"
        timeout="4000"
        location="bottom right"
        role="alert"
    >
      {{ t("contactForm.error") }}
      <template v-slot:actions>
        <HwButton color="white" text @click="showError = false" icon>
          <v-icon :icon="[mdiClose]"></v-icon>
        </HwButton>
      </template>
    </v-snackbar>
  </v-sheet>
</template>

<style lang="scss">
@use "assets/scss/settings";

.contact-form {

  .email-field :deep(.v-field) {
    padding-left: 24px !important;
  }

  .message-field :deep(.v-field) {
    padding: 6px 12px;
  }

  .contact-form--error :deep(*) {
    --v-theme-error: #1fabff !important;
  }

  :deep(.v-field--error .v-label.v-field-label) {
    opacity: 0.6 !important;
  }

  .hw-button--concern {
    background-color: rgb(var(--v-theme-primary-darken-3));
    color: white;
  }

  :deep(.v-field__outline) {
    color: var(--outline-color) !important;
  }

  .concern-card:hover,
  .concern-card:focus {
    background-color: rgba(var(--v-theme-background), 0.3);
  }

  .hw-hexagon {
    background-color: rgb(var(--v-theme-primary));
  }

  .hw-hexagon--mobile {
    display: none;
    @media only screen and (min-width: 360px) {
      display: block;
    }
  }

  .contact-card__option-image-wrapper {
    width: 110px;
    height: 110px;
  }

  .concern-card__description,
  .concern-card--mobile {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      height: 4px;
      bottom: -1rem;
      left: 0;
      background-color: rgb(var(--v-theme-primary));
      width: 0;
      transition: width 0.3s ease-in-out;
    }
  }

  /* Underline is full width when the card is selected */
  .concern-card--selected .concern-card__description::before {
    width: 100%;
  }


  .text-h2 {
    margin-left: auto;
    margin-right: auto;
    width: auto;
    text-align: center;
  }

}
</style>
